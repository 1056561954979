import React, { Component } from "react"
import styles from "./posts.module.css"
import { graphql } from 'gatsby'

class PostTemplate extends Component {

    formattedDate() {
        let date = new Date(this.props.data.wordpressPost.date)
        return date.getDate() + '.' + date.getMonth() + "." + date.getFullYear()
    }

    render() {
        const post = this.props.data.wordpressPost

        return (
            <div className="main-content">
                <div className={styles.post}>
                    <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
                    <div className={styles.attributes}>
                
                        <span className={styles.date} dangerouslySetInnerHTML={{ __html: this.formattedDate() }} />
                        <span className={styles.author} dangerouslySetInnerHTML={{ __html: post.author.name }} />
                        <div className={styles.categories}>
                            {post.categories.map(cat => {
                                return <div className={styles.category}>{cat.name}</div>
                            })}
                        </div>
                    </div>
                    <div className={styles.postContent} dangerouslySetInnerHTML={{ __html: post.content }} />
                </div>
            </div>
        )
    }
}


export default PostTemplate

export const pageQuery = graphql`
    query currentPostQuery($id: String!) {
        wordpressPost(id: { eq: $id }) {
            title
            content
            date
            author{
                name
            }
            categories{
                name
            }
        }
    }
`